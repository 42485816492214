<!--内购-->
<template>
    <div class="Receive">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <ef-biz-code v-model="form.code" />
                    </el-col>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <ef-review-status v-model="form.reviewStatus" />
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="内购人">
                            <el-select placeholder="请选择" v-model="form.receiveCode" filterable>
                                <el-option label="请选择" value="" />
                                <el-option
                                    v-for="item of staffArr"
                                    :label="item.realName"
                                    :value="item.code"
                                    :key="item.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <ef-stock-biz-status v-model="form.stockStatus" />
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <ef-start-date v-model="form.startTime" />
                    </el-col>
                    <el-col :span="8">
                        <ef-end-date v-model="form.endTime" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.stock.receive.open')">
                查询
            </el-button>
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.stock.receive.create')"
                >新建
            </el-button>
            <el-button
                type="primary"
                @click="showReviewDialog"
                :disabled="disableReviewBtn"
                size="small"
                v-if="hasPrivilege('menu.stock.receive.review')"
                >审核
            </el-button>
            <el-button
                type="primary"
                @click="changeMultipleBizStock"
                :disabled="disableChangeStockBtn"
                size="small"
                v-if="hasPrivilege('menu.stock.receive.stock')"
                >出库
            </el-button>
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.stock.receive.export')"
                >导出
            </el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <ef-review-biz ref="review" :baseUrl="baseUrl" @completed="handleQuery" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                border
                stripe
                ref="table"
                id="printMe"
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="430"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="编码" width="240" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构名称" width="160" prop="deptName" v-if="showColumn('deptName')" sortable />
                <el-table-column
                    label="审核状态"
                    width="140"
                    prop="reviewStatus"
                    key="reviewStatus"
                    v-if="showColumn('reviewStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.reviewStatus | reviewStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据状态"
                    width="140"
                    prop="stockStatus"
                    key="stockStatus"
                    v-if="showColumn('stockStatus')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.stockStatus | stockOutStatus }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="单据金额"
                    width="120"
                    prop="bizMoney"
                    key="bizMoney"
                    v-if="showColumn('bizMoney')"
                    sortable
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.bizMoney | moneyToYuan }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="出库时间"
                    width="160"
                    prop="stockChangeTime"
                    v-if="showColumn('stockChangeTime')"
                    sortable
                />
                <el-table-column label="创建人" width="120" prop="creator" sortable v-if="showColumn('creator')" />
                <el-table-column
                    label="创建时间"
                    width="160"
                    prop="createTime"
                    v-if="showColumn('createTime')"
                    sortable
                />
                <el-table-column
                    label="操作"
                    min-width="275"
                    header-align="center"
                    prop="operate"
                    key="operate"
                    v-if="showColumn('operate')"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.stock.receive.open')"
                            >查看
                        </el-button>
                        <el-button
                            size="mini"
                            type="warning"
                            v-if="hasPrivilege('menu.stock.receive.edit')"
                            @click="rowEdit(scope.row)"
                            :disabled="scope.row.disableEditBtn"
                        >
                            编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            v-if="hasPrivilege('menu.stock.receive.stock')"
                            @click="changeSingleBizStock(scope.row)"
                            :disabled="scope.row.disableChangeStockBtn"
                        >
                            出库
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            v-if="hasPrivilege('menu.stock.receive.delete')"
                            @click="handleDelete(scope.row)"
                            :disabled="scope.row.disableDeleteBtn"
                        >
                            删除
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <ef-pagination
                @pageChange="pageChange"
                :total="total"
                :default-limit="form.limit"
                :current-page="form.page"
            />
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';
import StockBizCommon from 'js/StockBizCommon';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';
import EfPagination from 'components/EfPagination';
import EfReviewBiz from 'components/EfReviewBiz';
import EfStockBizStatus from 'components/EfStockBizStatus';
import EfReviewStatus from 'components/EfReviewStatus';
import EfBizCode from 'components/EfBizCode';
import EfStartDate from 'components/EfStartDate';
import EfEndDate from 'components/EfEndDate';
import UrlUtils from 'js/UrlUtils';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'Receive',
    components: {
        EfEndDate,
        EfStartDate,
        EfBizCode,
        EfDeptTypeAndDeptSelect,
        EfPagination,
        EfReviewBiz,
        EfStockBizStatus,
        EfReviewStatus,
        CheckTableShowColumnDialog,
    },
    mixins: [CheckTableShowColumn],
    data() {
        return {
            selectedBizArr: [],
            baseUrl: this.$efApi.stockReceiveApi.baseUrl,
            total: 0,
            form: {
                code: '',
                deptCode: null,
                deptType: null,
                startTime: Util.formatDateOfLastMonth(),
                endTime: Util.nowFormatDate(),
                reviewStatus: '',
                receiveCode: '',
                stockStatus: '',
                page: 1,
                limit: 100,
            },
            tableData: [],
            staffArr: [],
        };
    },
    mounted() {},
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    watch: {
        'form.deptCode': {
            handler(n, o) {
                this.$efApi.staffApi.relatedStaff(n).then((rst) => {
                    this.staffArr = rst;
                });
            },
            immediate: true,
        },
    },
    computed: {
        //禁用/启用按钮
        disableReviewBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableReviewBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableReviewBtn;
            });
            return disableReviewBtnFlag >= 0;
        },
        disableChangeStockBtn() {
            if (this.selectedBizArr.length === 0) {
                return true;
            }
            const disableChangeStockBtnFlag = this.selectedBizArr.findIndex((e) => {
                return e.disableChangeStockBtn;
            });
            return disableChangeStockBtnFlag >= 0;
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        pageChange(page, limit) {
            this.form.page = page;
            this.form.limit = limit;
            this.queryList();
        },
        handleQuery() {
            this.form.page = 1;
            this.queryList();
        },
        changeMultipleBizStock() {
            const codes = this.selectedBizArr.map((e) => {
                return e.code;
            });
            this.changeStock(codes);
        },
        changeSingleBizStock(row) {
            this.changeStock([row.code]);
        },
        changeStock(codes) {
            const _this = this;
            UrlUtils.PostRemote(
                _this,
                '/stock/receive/out',
                {
                    codes: codes,
                    message: '确认出库该单据么?出库后该单据商品库存将立即变更,该操作不可撤销,请小心操作!!!',
                },
                null,
                () => {
                    this.$message.success('出库成功');
                    this.handleQuery();
                }
            );
        },
        showReviewDialog() {
            this.$refs.review.open(this.selectedBizArr);
        },
        async handleDelete(row) {
            UrlUtils.DeleteRemote(this, '/stock/receive/delete', row.code);
        },
        handleSelectionChange(selections) {
            this.selectedBizArr = selections;
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        setDeptType(deptType) {
            this.form.deptType = deptType;
            if (deptType) {
                this.handleQuery();
            }
        },

        async queryList() {
            const rst = await this.$efApi.stockReceiveApi.page(this.form);
            this.tableData = rst.data.map((e) => {
                return {
                    ...e,
                    //根据状态设置操作按钮禁用或启用
                    disableEditBtn: !StockBizCommon.validateCanEdit(e.reviewStatus),
                    disableDeleteBtn: !StockBizCommon.validateCanDelete(e.reviewStatus),
                    disableReviewBtn: !StockBizCommon.validateCanReview(e.reviewStatus),
                    disableChangeStockBtn: !StockBizCommon.validateCanChangeStock(e.reviewStatus, e.stockStatus),
                };
            });
            this.total = rst.count;
        },

        handleCreate() {
            Util.nameJump(this, 'menu.stock.receive.create', ['库存管理', '内购管理', '新建内购']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.stock.receive.detail', ['库存管理', '内购管理', '内购详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.stock.receive.edit', ['库存管理', '内购管理', '编辑内购'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);
            UrlUtils.ExportPost(this, '内购管理', '/stock/receive/exportPost', this.form, codes);
        },
    },
};
</script>

<style scoped>
.Receive .el-form-item {
    margin-bottom: 0;
}
</style>
